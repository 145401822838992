import React, { useState } from "react"
// import classes from './contact.module.scss';
import Layout from "../components/layout"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import IconButtons from "../components/Button/IconButtons"

const useStyles = makeStyles(theme => ({
  outerLayer: {
    display: "flex",
    padding: "0px 70px",
    justifyContent: "space-between",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      padding: "0px"
    }
  },
  root: {
    flex: "1",
    maxWidth: "858px",
    margin: "auto",
    "@media (max-width: 800px)": {
      padding: "16px",
      maxWidth: "654px",
      margin: "auto",
      width: "100%"
    }
  },
  heading: {
    paddingTop: "114px",
    display: "none",
    "@media (max-width: 800px)": {
      display: "flex",
      height: "114px",
      background: "#1CB0BA",
      paddingTop: "0px",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      marginLeft: "calc(-1 * ((100vw - 100%) / 2))"
    },
    "& h1": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "48px",
      lineHeight: "45px",
      color: "#17124e",
      padding: "0px",
      "@media (max-width: 800px)": {
        fontSize: "20px",
        lineHeight: "36px"
      }
    }
  },
  container: {
    paddingTop: "45px",
    "@media (max-width: 800px)": {
      paddingTop: "70px"
    },
    "& .MuiTextField-root": {
      height: "100%",
      width: "100%"
      // maxWidth: "310px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(0px, -24px) scale(1)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000000"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        "& legend": {
          display: "none"
        }
      }
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#17124E",
      borderWidth: "1px"
    }
  },
  firstRow: {
    display: "flex",
    flexDrection: "row",
    width: "100%",
    "& $left": {
      paddingRight: "16px"
    }
  },
  left: {
    flex: 1,
    height: "62px",
    paddingRight: "16px",
    "@media (max-width: 800px)": {
      paddingRight: "unset"
    }
  },
  right: {
    flex: 1,
    height: "62px",
    textAlign: "right"
  },
  SecondRow: {
    display: "flex",
    flexDrection: "row",
    width: "100%",
    paddingTop: "43px",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      "& .MuiTextField-root ": {
        maxWidth: "unset"
      }
    },
    "& $right": {
      "@media (max-width: 800px)": {
        marginTop: "45px"
      }
    }
  },

  TextAreaRow: {
    paddingTop: "57px",
    paddingBottom: "26px",
    "& .MuiTextField-root": {
      // maxWidth: "638px",
    },
    "& .MuiInputBase-input ": {
      // maxWidth: "638px",
      height: "139px"
    }
  },
  submit: {
    fontSize: "20px",
    margin: "17px 0px 64px",
    "@media (max-width: 800px)": {
      margin: "17px auto 0px"
    }
  },
  ThirdRow: {
    paddingTop: "43px",
    "& .MuiTextField-root ": {
      maxWidth: "unset"
    }
  },
  cardBody: {
    flex: "1",
    maxWidth: "546px",
    padding: "16px",
    maxHeight: "587px",
    background: "#F3F3F6",
    marginTop: "114px",
    marginLeft: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 800px)": {
      marginTop: "0px",
      maxWidth: "100%",
      marginLeft: "0px",
      marginBottom: "120px",
      padding: "35px",
      alignItems: "unset"
    }
  },
  cardtop: {
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "29px",
    width: "300px",
    "& b": {
      color: "white"
    },
    "@media (max-width: 800px)": {
      marginBottom: "41px"
    }
  },
  cardbottom: {
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "29px",
    width: "300px",
    "& b": {
      color: "white"
    }
  },
  cardbutton: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "29px",
    color: "#65C9D0",
    paddingTop: "30px",
    width: "300px"
  },
  Link: {
    margin: "auto",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "160px",
    "@media (max-width: 800px)": {
      height: "130px"
    }
  },
  footDetails: {
    height: "292px",
    backgroundColor: "#1CB0BA",
    display: "flex",
    marginBottom: "125px",
    "@media (max-width: 800px)": {
      height: "441px",
      width: "100vw",
      marginLeft: "calc(-1 * ((100vw - 100%) / 2))"
    }
  },
  footDetailsBody: {
    flex: "1",
    maxWidth: "878px",
    paddingLeft: "20px",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 800px)": {
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center"
    }
  },
  contactHead: {
    display: "flex",
    boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
    marginBottom: "140px",
    width: "100vw",
    marginLeft: "calc(-1 * ((100vw - 100%) / 2))",
    "@media (max-width: 800px)": {
      display: "none"
    }
  },
  contactHeadBody: {
    height: "100%",
    width: "100%",
    position: "relative"
  },
  imgContactUs: {
    position: "absolute",
    top: "50%",
    left: "15%",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "45px"
  },
  decoration1: {
    height: "90px",
    width: "90px",
    position: "absolute",
    background: "#E3D82F",
    top: "19%",
    left: "6%"
  },
  decoration2: {
    height: "90px",
    width: "90px",
    position: "absolute",
    background: "white",
    right: "0",
    bottom: "35%"
  },
  decoration3: {
    height: "90px",
    width: "180px",
    position: "absolute",
    background: "#17124E",
    right: "90px",
    bottom: "calc(35% - 90px)"
  }
}))

export default function Contact() {
  const data = useStaticQuery(graphql`
    query contactPageData {
      allDatoCmsContactPage {
        edges {
          node {
            heroImage {
              fluid(maxWidth: 1600) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const imgdata = data.allDatoCmsContactPage.edges[0].node.heroImage

  const classes = useStyles()
  const init = {}

  const inputHandler = (e, field) => {
    let myState = { ...state }

    myState[field] = e.target.value
    setState(myState)
  }

  const [state, setState] = useState(init)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": form.getAttribute("name"), ...state })
    })
      .then(data => {
        if (data.status === 200) {
          navigate("/success")
        }
      })
      .catch(error => alert(error))
  }

  return (
    <Layout pageTitle={"Contact"}>
      <div className={classes.contactHead}>
        <div className={classes.contactHeadBody}>
          <Image fluid={imgdata.fluid} className="gatsby-image-contact" />
          <h1 className={classes.imgContactUs}>Contact us</h1>
          <div className={classes.decoration1} />
          <div className={classes.decoration2} />
          <div className={classes.decoration3} />
        </div>
      </div>
      <div className={classes.outerLayer}>
        <div className={classes.root}>
          <div className={classes.heading}>
            <h1>Contact</h1>
          </div>
          <form
            className={classes.container}
            data-netlify="true"
            onSubmit={e => handleSubmit(e)}
            name="contact"
            data-netlify-honeypot="bot-field"
            method="post"
          >
            <div className={classes.firstRow}>
              <input type="hidden" name="form-name" value="contact" />
              <div className={classes.left}>
                <TextField
                  id="fullname"
                  label="Name"
                  name="Fullname"
                  variant="outlined"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={e => inputHandler(e, "name")}
                />
              </div>
              <div className={classes.right}>
                <TextField
                  id="customerid"
                  name="LastName"
                  label="Last Name"
                  variant="outlined"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={e => inputHandler(e, "LastName")}
                />
              </div>
            </div>
            <div className={classes.SecondRow}>
              <div className={classes.left}>
                <TextField
                  id="Email"
                  label="Email"
                  variant="outlined"
                  required
                  type="email"
                  name="Email"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={e => inputHandler(e, "Email")}
                />
              </div>
              <div className={classes.right}>
                <TextField
                  id="Phone"
                  label="Phone number"
                  variant="outlined"
                  name="Phonenumber"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={e => inputHandler(e, "Phonenumber")}
                />
              </div>
            </div>
            <div className={classes.TextAreaRow}>
              <TextField
                id="Description"
                name="Description"
                label="Message"
                variant="outlined"
                multiline
                required
                rows={3}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={e => inputHandler(e, "Description")}
              />
            </div>
            <div className={classes.submit}>
              <IconButtons type="submit">Send</IconButtons>
            </div>
          </form>
        </div>
      </div>
      <div className={classes.footDetails}>
        <div className={classes.footDetailsBody}>
          <div className={classes.cardtop}>
            <b>Hong Kong</b>
            <br />
            Bolttech Management Limited
            <br />
            25/F, Units 2501-06, FWD Financial Centre
            <br />
            308 Des Voeux Road Central
            <br />
            Hong Kong
            <br />
          </div>
          <div className={classes.cardbottom}>
            <b>Singapore</b>
            <br />
            Bolt Challenger Go Pte. Ltd.
            <br />
            9 Raffles Place
            <br />
            #26-01 Republic Plaza
            <br />
            Singapore 048619
            <br />
          </div>
        </div>
      </div>
    </Layout>
  )
}
